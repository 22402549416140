@import "../../styles.scss"; // This must be loaded first to overwrite bulma.

.InviteRecordView {
    border: 2px dashed $grey-4;
    height: 40px;
    border-radius: 8px;
    padding: 5px;
    line-height: 30px;
    margin-bottom: 10px;
    .squareAvatar{ float: left; margin-right: 10px;}
    span { 
        line-height: 30px; 
        display: inline-block; 
        margin-right: 10px;
    }
    .name {
        font-weight: bold;
        float: left;
        width: 50%;
        padding-right: 20px;
    }
    .email {
        float: left;
    }
    .remove {
        font-size: 13px;
        float: right;
        color:$text;
        cursor: pointer;
        display: none;
        margin-right: 10px;
        &:hover {
            color:$orange
        }
    }
    &:hover {
        .remove { display: inline-block; }
    }
}