@import "../../../../../styles.scss";

.CreationMethod {
 .control {
   display: flex;
   flex-direction: column;

   .radio {
     font-size: 24px;
     margin-bottom: 10px;
   }
   .radio + .radio {
     margin-left: 0;
   }
   .radio.is-disabled {
     color: $grey-0;
   }
 }
}
