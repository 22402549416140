@import "../../styles.scss"; // This must be loaded first to overwrite bulma.

.TwoColumnLayoutView {
    position: absolute;
    top:70px; right:0; bottom: 0; left:0; 
    display: flex;
    flex-direction: row;


    .leftColumn {
        width: 350px;
        background: $grey-4;
    }
    .twoColumnLayoutViewContent {
        background-color: #fff;
        flex-grow: 1;
        overflow-y: scroll;
    }
    .columnHeader {
        padding: 20px;
    }
    .TwoColumnLayoutViewNavItem {
        cursor: pointer;
        height: 50px;
        width: 350px;
        line-height: 50px;
        padding: 0 20px;
        font-size: 14px;
        &.selected {
            background-color: #fff;

        }
        
        &:hover {
            color: $orange;
            background-color: #fff;
        }
    }
}