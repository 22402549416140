// Import only what you need from Bulma
@import "styles.scss"; // This must be loaded first to overwrite bulma.

@import "../node_modules/bulma/sass/utilities/_all.sass"; // This must be loaded first of the bulma includes
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/components/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";
body {
  font-family: $family-primary;
}
.maintenanceMode {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 { font-size:  48px; font-weight: bold;}
}

.white-bg {
  background: #fff;
}
h4 { 
  color:$grey-0;
  margin-bottom:5px;
 }

 .columns.is-gapless > .column.padForMenu {
   padding-bottom: 110px !important;
 }

 .roundAvatar {
  display: inline-block;

   width: 30px;
   height: 30px;
   border-radius: 30px;
   background-color: $grey-2;
   overflow: hidden;
   &.huge {
    width: 76px;
    height: 76px;
    border-radius: 38px;
   }
 }
 .squareAvatar {
   display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: $grey-2;
  overflow: hidden;
}