.SentimentGroupExerciseView {
    position: absolute;
    top:0; left:0; right:0; bottom:0;
    padding-bottom: 80px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .sentimentColumn {
        padding: 0 60px;
        .sentimentHeader {
            padding: 10px 0;
            font-size: 24px;
            font-weight: 550;
            .rightHeader { float: right; }
        }
    }
}