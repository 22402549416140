.SentimentGroupItem {
    position: relative;
    margin-bottom: 20px;
    border-radius: 8px;
    .Button.is-tan.is-selected { background-color: #FFA800; }
    &.rightSide { 
        margin-left:200px;
        background-color: #FFF;
        .text{ 
            text-align: right;
        }
        .sentimentButtons {
            padding: 5px 200px 10px 0;
        }
        &.selected {
            // background-color:lighten(#FFF, 10%);
            .MiniAudioPlayer,
            .text { opacity: 0.5; }
        }
    }
    &.leftSide { 
        margin-right: 200px;
        background-color: #E9E3E0;
      
        .sentimentButtons {
            padding: 5px 0 10px 200px;
        }
        &.selected {
            // background-color:lighten(#E9E3E0, 3%);
            .MiniAudioPlayer,
            .text { opacity: 0.5; }

        }
    }
    .MiniAudioPlayer {
        .playbackProgress { display: none;}
        .control    { 
            position: relative;
        width: 20px;
        height: 20px;
        left:5px;
        line-height: 20px;
        border-radius: 10px;
        padding: 5px 0 0 1px;
        top: 20px;
        background-color: #FFA900;
        display: flex;
        align-self: center;
        justify-content: center;
        color: #fff;
        font-size: 10px;}
    }
    .text {
        padding: 10px;
        border-radius: 6px;
        margin: 0 10px 5px 30px;  

    }
    .topRow {
        padding: 5px ;
        position: relative;
    }
    .sentimentButtons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .Button { margin: 0 10px;}
        
    }
   

}
