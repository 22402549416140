.StaticTextwithEntities {
    line-height: 35px;
    span.entity {
        padding: 0 2px;
        margin: 0 2px;
        position: relative;
        span {
            position: absolute;
            font-size: 10px;
            font-weight: bold;
    
            position: absolute;
            left: 50%; /* position the left edge of the element at the middle of the parent */
        
            transform: translate(-50%, 0);
            text-align: center;
    
            bottom: -15px;
            line-height: 15px;
            white-space: nowrap;
        }
    }
}