@import "../../../styles.scss";

.ModalCard {
  position: absolute;
  top:0; bottom:0; left:0; right:0;
  opacity: 0;
  overflow: hidden;
  transition: 0.5s all ease;
  z-index: 1;


  .modalHeader {
    position: absolute;
    top: 40px;
    right: 40px;
    left: 40px;

    h3 {
      font-family: $family-secondary;
      font-weight: 550;
      font-size: 24px;
    }
  }
  .modalContent {
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 80px;
    right: 40px;
    left: 40px;
    bottom: 80px;
  }


  .modalFooter {
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
    .buttons .button {margin:  8px;}
  }
  .modalHeader.fullWidth
  .modalContent.fullWidth,
  .modalFooter.fullWidth { left:0; right:0; }



  .ProgressBlock {
    width: 600px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: dashed 2px $grey-4;
    padding: 60px 61px 0;
    h4 {
      font-size: 18px;
      color: $text;
      margin: 15px;
    }
    span { color: $grey-2}
  }
}
.ModalCard.isShowing {
  opacity: 1;
  z-index: 10;
}
