@import "../../../../../styles.scss"; // This must be loaded first to overwrite bulma.

.MLModelSelectionView {
    margin-bottom: 20px;

    
    .Pill {
        background-color: #fff;
    }
    .AddMLModel {
        margin-top: 5px;
        background: $poppy;
        padding: 10px 15px 20px 20px;
        width: 50%;
        border-radius: 8px;
        clear: both;
        box-shadow: 0 0 4px 1px rgba(0,0,0,0.05);
        h4{ color:$text; }
        .button {
            margin-right: 5px;
        }
        .selectRow {
            display: flex;
            flex-direction: row;
            .selectML {
                flex-shrink: 0;
                flex-grow: 1;
                margin-right: 10px;
            }
        }
    }
}