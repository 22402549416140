@import "../../../styles.scss"; // This must be loaded first to overwrite bulma.

.ImageCategorizeCreateView {
    padding-top: 30px;
    h3 {
        font-size: 18px;
        font-weight: 600;
        font-family:$family-secondary;
        margin-bottom: 5px;
    }
    .columnNameAndRedundancy {
        width: 100%;
        clear: both;
        height: 80px;
        margin-bottom: 10px;

        .columnName {  float:left; width:  300px; margin-right: 10px;}
        .redundancy { float:left; width:  180px; }

    }


    .categoryContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        div {
            color:$text;
            flex:1;
        }
        span { 
            color: $grey-0; 
            margin-left: 10px;
        }
    }
}