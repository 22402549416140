.navbar {
  box-shadow:0 2px 4px 0 rgba(0,0,0,0.15);
  height: 70px;
  .columns {

    width: 100%;

    h3 {
      font-size: 18px;
    }
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .left-column {
      text-align: left;
      justify-content: flex-start;

      .NavTextButton {
        margin-left: 40px;
      }
    }
    // .center-column {

    // }
    .right-column {
      text-align: right;
      justify-content: flex-end;

      .NavTextButton {
        margin-right: 40px;
      }
      .Switch { 
        float: right; 
        margin: 0 40px 0 10px;}
    }
  }
}
