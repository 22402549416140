.DeleteEntityPopover {
    position: absolute;
    font-size: 18px;
    font-weight: bold;

    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, 0);
    text-align: center;
    filter: drop-shadow(0 3px 24px rgba(0,0,0,0.12));

    bottom: 38px;
    line-height: 15px;
    white-space: nowrap;
    padding: 8px 0;
    width: 40px;
    border-radius: 8px; 
    background-color: #fff;
    color: #5E5450;
    &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-top-color:  #fff;
        border-width: 5px;
        margin-left: -5px;

    }
}

.DeleteEntityPopover:hover {
    color: #fff;

    background-color: #E92200;
    &:after {
        border-top-color:  #E92200;

    }
}