@import "../../../../styles.scss"; // This must be loaded first to overwrite bulma.

.ProjectAndMembersView {
    margin-bottom: 30px;
    .projectTitle {
        height: 30px;
        width: 100%;
        margin-bottom: 10px;
        padding-left: 5px;
        .roundAvatar { float: left; margin-right: 10px;}
        h2 {
            float: left;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            margin-right: 20px;
        }
        .archiveButton {
            display: none;
            float: left;
            font-size: 13px;
            font-weight: normal;
            line-height: 30px; 
            margin-right: 10px;
            cursor: pointer;
            color:$grey-0; 
            &:hover { color:$orange; }
            &:active { color:$yellow; }
        }

        &:hover {
            .archiveButton { display: block;}
        }
    }

    .addUser {
        padding: 10px 40px;
    }
}