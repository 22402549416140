
.TimelineSegment {
    box-sizing: border-box;

    border-radius: 8px;
    height: 44px;
    position: absolute;
    top:0;
    z-index: 200;
    border-left: 5px solid;
    border-right: 5px solid;
    border-top: 2px solid;
    border-bottom: 2px solid;
    
    .leftHandle, .rightHandle {
        opacity: 0;
        transition: all 0.2 ease;
        position: absolute;
        top:-2px;
        height: 44px;
        width: 5px;
        border-radius: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        .marker {
            width: 3px;
            height: 15px;
            background-color: #fff;
            border-radius: 1.5px;
        }
    }

    .leftHandle { left:-5px; } 
    .rightHandle { right:-5px; }

    
    .segmentMenu {
        position: absolute;
        height: 44px;
        top:-61px;
        width: 100px;
        border-radius: 5px;

        left: 50%; /* position the left edge of the element at the middle of the parent */
    
        transform: translateX(-50%);

        a {
            float: left;
            position: relative;
            z-index: 10;
            width: 50px;
            height: 44px;
            font-size: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .segmentMenuArrow {
            width: 25px;
            height: 25px;
            transform: rotate(45deg);
            position: absolute;
            bottom: -7px;
            border-radius: 3px;
            left: 37px;
        }
    }


}
.TimelineSegment:hover,
.TimelineSegment:active {
    .leftHandle, .rightHandle {
        opacity: 1;
    }
}


// .TimelineSegment.isSliding {
//     box-shadow: 0 0 4px 2px rgba(255,255,255,0.6);
// }