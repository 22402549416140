@import "../../../styles.scss"; // This must be loaded first to overwrite bulma.
.TextCategorizeResolutionView {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .textRow {
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 600;
        padding: 0 40px;
        max-width: 940px;
        text-align: center;
        color: #5E5450;
        cursor:default;
    }

    .selectionWrapper {
        max-width: 330px;
        width: 330px;
        
        background-color: #fff;
        border-radius: 8px;
        position: relative;
        padding: 5px 10px 10px 10px;

        label {
            color:#5E5450;
            text-transform: none;
            font-size: 12px;
            margin-bottom: 3px;
        }
    }
}