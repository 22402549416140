@import "../../../../../styles.scss";

.ConfirmCard {
  .modalContent {
    flex-direction: column;
  }
  .datarow {
    .Pill { margin: 0 10px 10px 0;}
    width: 100%;;
    margin-bottom: 20px;
    h3 {
      font-size: 1.3em;
      margin: 0.3em 0 ;
    }
    div {margin-bottom: 5px;}
  }
  
}
