@import "../../../../styles.scss"; // This must be loaded first to overwrite bulma.

.NavTray {
    position: fixed;
    width: 232px;
    left:0;top:0;bottom:0;
    background-color: #fff;
    box-shadow: 0  6px 12px 0  rgba(65,59,56,0.25);
    z-index: 1000000;
    transform:translateX(-250px);

    &.show {
        transform:translateX(0);
    }
    transition: all 0.2s ease-in;
    .mainMenu {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .mainNavButton {
            width: 100%;
            font-size: 15px;
            padding: 0 14px;
            font-family: $family-secondary;
            font-weight: 600;
            color: $text;
            height: 55px;
            line-height: 55px;
            border-left: 4px solid #fff;
            span.icon {
                float: right;
                               width: 55px;
                height: 55px;
            }

            &:hover {
                background-color: $grey-5;
                border-left: 4px solid $grey-5;

            }
            &.selected {
                background-color: $poppy;
                border-left: 4px solid $orange;

            }
        }
    }
    .settingsButton {
        position: absolute;
        bottom:0; 
        left:0;
        padding: 14px;
        font-size: 20px;
        cursor: pointer;
        color:$text;
        &:hover { color:$orange}
    }
}