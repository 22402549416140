@import "../../../../styles.scss";
.FinishCard,
.ExerciseCard {
  position: absolute;
  top:150px; bottom:0; left:0; right:0;
  opacity: 0;
  transition: 0.5s all ease;
  z-index: 1;

}

.instructionsExpanded .FinishCard,
.instructionsExpanded .ExerciseCard {
  left: 370px
  
}
.FinishCard.isShowing,
.ExerciseCard.isShowing {
  opacity: 1;
  z-index: 10;
}

.FinishCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 60px;
  h2 {
      text-align: center;
      color:$dark-brown;
      font-family: $family-secondary;
      font-weight: 550;
      font-size: 32px;
      margin-bottom: 40px;
  }
  .imageWrapper {
    width: 400px;
    height: 400px;
    border-radius: 200px;
    overflow: hidden;
    margin-bottom: 40px;
  }
  .button {
    font-family: $family-secondary;
    font-weight: 550;

  }
  
}

.ExerciseKey {
  position: absolute;
  right: 40px;
  bottom:10px;
  display: flex;
  flex-direction: column;
  .ExerciseKeyItem {
    margin-bottom: 10px;
    .icon {
      float: right;
      width: 30px;
      height: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $grey-2;
      border-radius: 4px;
      margin-left: 10px;
      .med {
        font-size: 9px;

      }
      small { 
        font-size: 7px;
        font-family: $family-secondary;
        font-weight: 550;
      }
      .key {
        font-size: 14px;
        font-family: $family-secondary;
        font-weight: 550;
      }
    }
    .text {
      text-align: right;
      float: right; 
      line-height: 30px;
    }
  }
}