@import "../../../styles.scss";

.MLModelDetailsView{

  .detailsHeader {
    position: relative;
    padding: 26px 20px 60px 20px;
    color:$text-light;
    h2 {
      font-size: 2em;
      font-family: $family-secondary;
      color: $text;
    }
    .actionButton {
      position: absolute;
      right: 20px;
      top:26px;
    }
  }
  .ProgressBlock {
    padding: 40px 30% 40px 30%;
  }
  .Pill { background-color: #fff; margin: 0 10px 10px 0 ;}
  

  .dataRep {
      padding: 0 20px 60px;
      h3 { font-size: 1.5em; margin: 20px 0 10px;}
    div { 
        margin-bottom: 10px;
    }
  }
  .subCategories {
    padding: 0 0 20px 60px;
  }
}
