@import "../../../styles.scss";

.JobDetailsView {
  padding: 40px;



  .reviewStats {
    display: flex;
    flex-direction: row;
    margin-bottom: 80px;
    .labelButtonWrapper {
      margin-right: 15px;
      cursor: pointer;
      h2 {
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 5px;
        font-weight: 550;
        color: $light-brown;
      }
      .labelButton {
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.15);
        width: 160px;
        height: 110px;
        text-align: center;
        padding-top: 25px;
        font-size: 14px;
        color:$grey-0;
        h3 {
          font-size: 32px;
          font-weight: 550;
          color:$teal;

        }
        h3.escalations { color: $orange}
      }
    }
  }
  .labelButtonWrapper:hover .labelButton{ background-color: $poppy;   }
  .labelButtonWrapper:active .labelButton{ box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
  }

}
