@import "../../../../../styles.scss";

.UploadRowPicker {
  .tableWrapper {
    width: 100%;
    padding: 0 40px;
    overflow: scroll;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 30px;
    max-height: 450px;
  }
  table {
    width: 100%;
    td,  th { padding: 5px; min-width: 130px;}
    thead tr {background: $grey-5; }
    tbody tr:nth-child(even) {background: $grey-5;}
  }
}
