@import "../../../styles.scss";

.ImageCategorizeReviewItem {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    margin: 0 20px 20px 20px;
    padding: 25px;
    .completeIcon {
        position: absolute;
        left: -4px;
        top:-4px;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: $teal;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 4px;
    }
    h3 {
        
        font-size: 18px;
        color:$dark-brown;
        font-weight: 500;
        font-family: $family-secondary;
        margin-bottom: 10px;
    }
    .button {
        margin-right: 10px;}
    //     background-color: #F5F1EF;
    //     color: #413B38;
    // }
    // .button:hover {
    //     background-color: #413B38;
    //     color: #FFF;

    // }
    .skippedButton,.skippedButton:hover,.skippedButton:active {
        color: #413B38;
    border: 1px solid #C3BCB9;
    background-color: transparent;
    cursor: not-allowed;
    }

}