.InstructionBox {

    width: 338px;
    padding:10px; 
    transition: all 1s ;
    float: left;
    background:#fff;
    margin-right: 30px;
    h3 {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 20px
    }
    &.open {
        width: 400px;
        height: 600px;
    }
}