@import "../../../styles.scss"; // This must be loaded first to overwrite bulma.

.Modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    .ModalContent {
      position: relative;
      width: 90vw;
      height: 90vh;
      background: #fff;
      // box-shadow: 0 0 4px 3px rgba(0,0,0,0.15);
      border-radius: 6px;
    }
    .closeButton {
      position: absolute;
      top:10px; right:10px;
      width: 36px; height:36px;
      color: $grey-2;
      z-index: 1000;
    }
    .closeButton:hover {
      color: $grey-0;
    }

  }
