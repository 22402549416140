.SelectionView.doneSelecting {
    z-index: 500;
}
.SelectionView {
    position: absolute;
    top:0; bottom:0; 
    z-index: 20;
    .mainSelection {
        position: relative;
        width: 100%;
        background-color: rgba(255,169,0, 0.15);
        height: 154px;
        margin-bottom: 10px;
        
        .playButton {
            position: absolute;
            top:0; right:0; left:0; bottom:0;
            display: flex;
            align-items: center;
            justify-content: center;
            .playButtonIcon {
                border-radius: 25px;
                font-size: 20px;
                text-align: center;
                line-height: 50px;
                background-color: #FFA900;
                color: #fff;
                width: 50px;
                height: 50px;
                flex-shrink: 0;
                &.playIcon {
                    padding-left: 5px;
                }
            }
        }

    }

    .rowSelection {
        position: relative;
        width: 100%;
        background-color: #C7DFDA;
        border-radius: 5px;

  
        height: 44px;
        margin-bottom: 10px;
    }
    .rowSelection.doneSelecting {
        border: 2px dashed #00CABE;
    }
    .rowSelection.doneSelecting:hover {
        background-color:  #00CABE;
        border: 2px solid #027C74;
        span  {background-color: #fff;}
    }

    .rowSelection.doneSelecting:active {
        background-color:  #00CABE;
        span {background-color: rgba(255,255,255,0.7);}
    }
    .selectionButton {
        position: absolute;
        top:0; right:0; left:0; bottom:0;
        display: flex;
        align-items: center;
        justify-content: center;
        .selectionIcon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 31px;
            height: 31px;
            border-radius: 15px;
            flex-shrink: 0;
            color: rgba(255,169,0, 1);

            span {  
                position: absolute;
                display: block;
                width: 3px;
                height: 25px;
                background-color: #00CABE;
                border-radius: 1.5px;
                top:3px;
                left: 13px;
            }
            span.cross {
                position: absolute;
                display: block;
                width: 25px;
                height: 3px;
                border-radius: 1.5px;
                top:13px;
                left: 2px;
            }
        }
    }
 

    
}