@import "../../../../../styles.scss"; // This must be loaded first to overwrite bulma.

.NewProjectModal {
    h1 {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        font-family:$family-secondary
    }
    .buttons {
        display: flex;
        flex-direction: row;
        padding-top: 15px;
        .Button {
            flex:1
        }
    }
}