@import "../../../../../styles.scss";
.ExerciseNav {
    position: absolute;
    background-color: $grey-3;
    height: 50px;
    width: 100%;

    



    .col{
        position: absolute; 
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 0  40px;
        top:0;
        
    }
    .col.leftCol  { left:0; width: 210px; }
    .col.centerCol { left:210px; right:210px; }
    .col.rightCol  { right:0; width: 410px; justify-content: flex-end; }

    .col.centerCol h2 {
        font-family: $family-secondary;
        font-weight: 550;
        font-size: 16px;
        @extend %noselect;

    }
    .skipAndDone {
        display: flex;
        flex-direction: row;
        .ExerciseNavButton { margin-left: 10px;}
    }
    .ExerciseNavButton {
        @extend %noselect;
        width: 90px;
        height: 34px;
        font-family: $family-primary;
        font-weight: 550;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 17px;
        background-color: #fff;
        cursor: pointer;
        color:$text; 
        &.hideBg {
            background-color:transparent;

        }
        &:active {
            background-color: $yellow;
            color: #fff;
        }
        &:hover {
            background-color: $orange;
            color: #fff;
        }
    }
    .ExerciseNavButton.isDisabled {
        @extend %noselect;
        width: 90px;
        height: 50px;
        font-family: $family-secondary;
        font-weight: 550;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        background-color: #fff;
        cursor: pointer;
        opacity: 0.6;
        &.hideBg {
            background-color:transparent;

        }
        &:active {
            background-color:#fff;
            color:$text-light; 
        }
        &:hover {
            background-color: #fff;
            color:$text-light; 
        }
    }


}