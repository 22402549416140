@import "../../../styles.scss"; // This must be loaded first to overwrite bulma.

.EntityReviewCustomModal {
    h1 {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        font-family:$family-secondary;
        margin-bottom: 20px;
        color: $text-light;
    }
    .buttons {
        display: flex;
        flex-direction: row;
        padding-top: 15px;
        .Button {
            flex:1
        }
    }
    .buttonOrSpinner {
     
        width: 100%;
        height: 60px;
        .loadingSpinner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 60px;
        }
    }
    .Button {
        width: 100%;
    }
}
