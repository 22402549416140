@import "../../../styles.scss";

.TranscribeReviewItem {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    margin: 0 20px 20px 20px;
    padding: 80px 25px 25px 25px;
    .player {
        border-radius: 8px 8px 0 0;

        position: absolute; 
        left: 0; top:0; right:0;
        
        height: 60px;
        overflow: hidden;


    }
    .Pill { margin-top: 15px;}
    .SaveInput {
        width: 100%;
    }
    .completeIcon {
        position: absolute;
        left: -4px;
        top:-4px;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: $teal;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 4px;
    }
    h3 {
        
        font-size: 18px;
        color:$dark-brown;
        font-weight: 500;
        font-family: $family-secondary;
        margin-bottom: 10px;
    }
    .button {
        margin-right: 10px;}
    //     background-color: #F5F1EF;
    //     color: #413B38;
    // }
    // .button:hover {
    //     background-color: #413B38;
    //     color: #FFF;

    // }
    .skippedButton,.skippedButton:hover,.skippedButton:active {
        color: #413B38;
    border: 1px solid #C3BCB9;
    background-color: transparent;
    cursor: not-allowed;
    }

    .radioButton {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #C3BCB9;
        margin-right: 10px;
        display: flex; align-items: center; justify-content: center;
        float: left;
        span {
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 8px;

        }
    }
    .TranscribeReviewItemOption.selected .radioButton span, 
    .TranscribeReviewItemOption:hover .radioButton span {
        background-color: $yellow;
    }

}
.TranscribeReviewItemOption {
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: row;

    min-height: 40px;
    &.customOption {
        align-items: center;
        margin-bottom: 10px;
    }
    .control {
        position: absolute;
        left: 40px;
        right: 20px;
    }
}

