.ImageCategorizeExerciseView {
    height: 100%;
    .imageAndButtons {
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 0 30px; 
        transition: 0.5s all ease;

    }
    .imageWrapper {
        transition: 0.5s all ease;

        height: 100%;

        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            height: 100%;
        }
    }
    .buttonsArea{
        h3 {
            svg { margin-right: 10px; cursor: pointer;}
            font-size: 18px; 
            font-weight: bold;
        }

        transition: 0.5s all ease;

        margin-top: 10px;
        flex-grow: 2;

        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 80px;
        flex-shrink: 0;
    }
}