.WaveformView {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;

    height: 154px;
    .waveformSpinner {
        height: 154px;
        width: 154px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}