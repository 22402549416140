.ProjectsView {
    padding: 20px;
    position: relative;
    height: 100%;;
    .TabHeader { margin-bottom: 20px; }
    .blankState { 
        position: absolute;
        top:80px; left:80px;right:80px;bottom:80px; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .addProjectButton {
        position: absolute;
        right: 30px;
        top:30px;
        font-size: 13px;
    }
}