@import "../../styles.scss";

.inProgressCell {
    height: 90px;
    padding: 30px 40px;
    text-align: center;
    background: $poppy;
    font-family: $family-secondary;
    font-weight: 550;
    cursor: pointer;
    border-bottom: 1px solid $yellow;

}
.inProgressCell:hover {
    color: #fff;
    background: $yellow;
    cursor: pointer;
}