.CursorView {
    position: absolute;
    top:0;
    bottom:0; 
    left:0;
    width: 4px;
    z-index: 51;


    .mainCursor {
        border-radius: 2px;
        width: 4px;
        background-color: #F67902;
        height: 154px;
        margin-bottom: 10px;
    }
    
    .rowCursor {
        border-radius: 2px;
        width: 4px;
        background-color: #F67902;
        height: 44px;
        margin-bottom: 10px;
    }

}
.CursorView.isDraggingSegment .rowCursor:hover { opacity: 0; }