@import "../../../styles.scss";

.DataSetDetailsView{

  .detailsHeader {
    position: relative;
    padding: 26px 20px 120px 20px;
    color:$text-light;
    h2 {
      font-size: 2em;
      font-family: $family-secondary;
      color: $text;
    }
    .actionButton {
      position: absolute;
      right: 20px;
      top:26px;
    }
  }
  .ProgressBlock {
    padding: 40px 30% 40px 30%;
  }
  .deleteButton {
    position: absolute;
    bottom:0; 
    left: 20px;
  }
  table {
    width: 100%;
    td,  th { padding: 10px;}
    th.checkboxColumn,
    td.checkboxColumn { padding: 10px 20px 10px 30px; }
    tbody tr:nth-child(odd) {background: #FFF}
  }
  .categoryPill{
    span { font-size: 8px; display: block;}
    background-color: $grey-3;
    padding: 2px 5px;
    font-size: 11px;
    border-radius: 10px;
    float: left;
  }
}
