.ImageReviewImage {
    height: 150px;
    width: 250px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    transition: 0.3s all ease;
    img {
        max-width: 100%;
        height: auto;
        
        display: block;
   
    }
    &.isExpanded {

        // height: 800px;
        width: 800px;
        height: auto;
        // width: auto;
        // max-height: 800px;
        // max-width: 800px;
    }
}