@import "../../../styles.scss";

.MiniAudioPlayer {
        position: absolute; 
        left: 0; top:0; right:0; bottom:0;

        .control {
            position: absolute;
            width: 60px;
            height: 60px;
            padding-top: 13px;
            left: 0; top:0;
            background-color: $yellow;
            display: flex; 
            align-self: center; 
            justify-content: center;
            color: #fff;
            font-size: 30px;
        }
        .playbackProgress {
            position: absolute; 
            left: 60px; top:0; right:0; bottom:0;
            background-color: $grey-3;
        }
}