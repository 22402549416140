@import "../../../../../styles.scss";

.JobHeaderView {
    padding: 0 0 20px;
    color:$text-light;
    h2 {
      font-size: 2em;
      font-family: $family-secondary;
      color: $text;
      span { 
        font-size: 1em; 
        color: $grey-1;
       }
    }
    h2.clickableIconHeader { cursor: pointer;}
    h2.clickableIconHeader:hover span { color: $orange; }
    .actionButton {
      position: absolute;
      right: 20px;
      top:26px;
    }

    .editJobName {
      display: flex;
         .control { 
           width: 70%;
            margin: 0 20px 0 0;
            color:$text;
         }
    }
  }