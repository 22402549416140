@import "../../../../../styles.scss"; // This must be loaded first to overwrite bulma.

.NavProjectItem {
    height: 48px;
    width: 100%;
    padding: 0 14px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .projectAvatar { margin-right: 10px;}
    .projectName {
        font-size: 13px;
        color: $text;

    }
    &:hover { background: $grey-5; }
    &:active { background: $poppy; }
}

.OrgGroup {
    h3 { 
        padding: 0 14px;
        font-family: $family-secondary;
        font-weight: 600;
        color: $text;
        cursor: pointer;
    }
    margin-bottom: 20px;
    .noProjects {
        font-size: 13px;
        color: $text-light;
        padding: 7px 14px;
    }
}

.NavProjectSelector {

    .SelectedProjectHeader {
        position: absolute;
        top:0; 
        right: 0;
        background-color: #fff;
        height: 54px;
        width: 100%;
        padding: 0 14px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid $grey-4;
        color: $text;
        z-index: 2000;

        .projectAvatar { 
            margin-right: 10px;
            flex-shrink: 0;
        }
        .selectedProjectTitle {
            flex: 1;
        }
        svg {
            flex-shrink: 0;
        }
        &:hover{ svg {
            color: $orange;
        }}

    }
 
 
    .projectList {
        position: fixed;
        height: 100vh;
        left: 0;
        top:0; 
        width: 232px;
        padding-top: 54px;

        transition: all 0.2s ease-in;
        transform: translateY(-100vh);
        background-color: #fff;
        z-index: 1000;
        overflow-y: scroll;
        &.show {
            transform: translateY(0);
        }
        .searchArea {
            padding: 14px 14px 18px;
        }
    }
}