@import "../../../styles.scss";



.EntityReviewItem:nth-child(odd) {background: #EFE9E5}
.EntityReviewItem { 
    position: relative;
    margin: 0 10px;
    padding: 10px;
    .completeIcon {
        position: absolute;
        left: 8px;
        top:8px;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: $teal;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 4px;
    }
}

.EntityReviewItemOption {
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: start;
    .content {
        display: flex;
        flex-direction: column;
    }
    .optionDot {
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: center;
        margin-right: 10px;
        .dotContent {
        flex-shrink: 0;
        border-radius: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 2.5px solid #A8A4A2;
            span {
                width: 10px;
                height: 10px;
                display: none;
                border-radius: 5px;
                background-color: $yellow;

            }

        }
    }
}

.EntityReviewItemOption.selected .optionDot, .EntityReviewItemOption:hover .optionDot {

    .dotContent { border-color: $yellow;}
    span { display: block;}
} 