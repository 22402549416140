@import "../../../styles.scss"; // This must be loaded first to overwrite bulma.
.SelectEntityPopover {
    position: absolute;
    font-size: 10px;
    font-weight: bold;

    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, 0);
    text-align: left;
    filter: drop-shadow(0 3px 24px rgba(0,0,0,0.12));

    bottom: 38px;
    line-height: 15px;
    white-space: nowrap;
    padding: 16px 0;

    background-color: #fff;
    border-radius: 8px; 
    
    &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-top-color: #fff;
        border-width: 5px;
        margin-left: -5px;
    }
}
.SelectEntityPopoverItem {
    line-height: 25px;
    font-weight: 600;
    font-size: 15px;
    padding: 0 16px;
    font-family: $family-primary;
    &.color_aqua    { color: #00CABE; &:hover { background-color: #E9FFFE;}}
    &.color_red     { color: #FD583B; &:hover { background-color: #FFE8E4;}}
    &.color_purple  { color: #BD10E0; &:hover { background-color: #ECCEF2;}}
    &.color_blue    { color: #0087FF; &:hover { background-color: #E2EFFA;}}
    &.color_yellow  { color: #FFA900; &:hover { background-color: #FFF5E2;}}
}
