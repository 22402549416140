@import "../../styles.scss";

.AudioCategorizeAndSplitExercisesView {
    position: absolute;
    left: 0;
    right: 0;
    bottom:0;
    top:0;
    .leftColumn {
        position: absolute;
        left: 0;
        top: 0;
        bottom:0;
        width: 215px;
        border-right: 1px solid #C3BCB9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    
    

}
.rightColumn {
    position: absolute;
    left: 215px;
    top: 0;
    bottom:0;
    right: 0;
    padding: 0 60px 0 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
}
}
