@import "../../styles.scss"; // This must be loaded first to overwrite bulma.

.LayoutView {
  .screen {
    position: absolute;
    top:70px; right:0; bottom:0; left:0;
    overflow: hidden;
    .full-height-columns {
      position: absolute;
      top:0; right:0; bottom:0; left:0;
      overflow-x: hidden;
      overflow-y: scroll;
 
    }
    .full-height-columns > .column {
      overflow-y: scroll;

    }
    .bordered-column {
      border-right: 1px solid $grey-2;
    }
  }
}
