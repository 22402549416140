.TranscribeExerciseView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    textarea {
        max-height: 50vh;
        width: 81vw;
        border: 1px solid #A8A4A2;
        border-radius: 8px;
        padding: 10px;
        resize: none;
        font-size: 24px;
        color:#5E5450;
        &:focus {
            outline: none !important;
        }
    }
}