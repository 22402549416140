.TimelineTracksTrack {
    width: 100%;
    width: 420px;
    position: relative;
    background-color: #E9E3DF;
    border-radius: 8px;
    height: 44px;
    margin-bottom: 10px;
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
    z-index: 10;
    transition: 0.3s all;
}
