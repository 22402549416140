@import "../../../styles.scss";

.TimelineControlsView {
    width: 215px;
    height: 154px;
    padding: 0 10px;
            float: left;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .iconAndText {
                width: 50px;
                span {
                    font-size: 14px;
                    color:$dark-brown;
                    text-align: center;
                    display: block;
                }
                .icon {
                    width: 50px;
                    height: 50px;
                    background: $dark-brown;
                    border-radius: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color:#fff;
                    cursor: pointer;
                    margin-bottom: 5px;
                    &:hover {  background-color: $yellow; }
                    &:active {  background-color: $orange; }
                    &.play { padding-left: 3px;}
                    &.forward {
                        transform: scaleX(-1);
                    }
                }

            }
        }