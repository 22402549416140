@import "../../../../../styles.scss";

.InstructionsView {
    position: absolute;
    top: 170px; 
    left: 20px;
    height: 60px;
    width: 60px;
    background-color: #fff;
    z-index: 9999;
    transition: 0.5s all ease, 0s color;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: -3px 0 6px 0 rgba(0,0,0,0.15);
    cursor: pointer;
    &:hover {
        box-shadow: -3px 0 6px 0 rgba(0,0,0,0.35);
        .questionMarkMask { color: $text; }
    }

    .instructionsLoader {
        position: absolute;
        top:0; left:0; right:0; bottom: 0;
        display: flex;
        align-items: center; 
        justify-content: center;
    }

    .questionMarkMask {
        position: absolute;
        top:0; left:0; right:0; bottom: 0;
        display: flex;
        align-items: center; 
        justify-content: center;
        // transition: 0.5s all ease;
        
        z-index: 2000;
        background-color: #fff;
        cursor: pointer;
        color: $grey-0;
        &:hover { color: $text; }
        
    }
    .instructionsContent {
        position: absolute;
        width: 350px;
        padding: 15px;
        opacity: 0;
        transition: 0.5s all ease;
        overflow-y: scroll;
        top:0; left:0; bottom: 0;
        z-index: 100;
    }
    
    
    .markdown {
        h1 { font-size:  24px; font-weight: bold;}
        h2 { font-size:  18px; font-weight: bold;}
        h3 { font-size:  16px; font-weight: bold;}
        font-size: 14px;
        p {
            margin-bottom: 10px;
        }
    }
}
.instructionsExpanded .InstructionsView {
    height: auto;
    bottom:20px;
    width: 350px;
    .questionMarkMask { display: none; }

    .instructionsContent { opacity: 1;}
}

