@import "../../../../styles.scss";

.AssignmentProgress {
    width: 417px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    .textRow div { float: left;}
    .textRow div:last-child {
        float: right;
    }

    .progressBar {
        height: 6px;
        width: 417px;
        background-color: $grey-3;
        border-radius: 3px;
        overflow: hidden;
        .progress {
            background-color:$teal;
            height: 6px;
            border-radius: 0 3px 3px 0;
        }
        

    }
}