.CategoryTitles {
    width: 198px;
    padding-top: 10px;
    clear: both;

    h1 {
        display: block;
      width: 100%;
      height: 44px;
      line-height: 44px;
      background-color: #FFA900;
      border-radius: 5px;
      margin-bottom: 10px;
      text-align: center;  
      color: #fff;
      font-size: 18px;
      font-family:'Source Sans Pro', sans-serif;
      text-transform: uppercase;
      font-weight: 550;
    }
    
}
