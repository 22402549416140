@import "../../styles.scss"; // This must be loaded first to overwrite bulma.

.AddUserPopover {
    width: 254px;
    height: 276px;
    transition: 0.3s all;
    header {
        width: 254px;
        height: 52px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        .control { width:180px;}

        .cancelButton { 
            float: right;
            font-size: 14px;
            font-weight: 600;
            line-height: 30px;
            text-align: center;
            width: 54px;
            padding-left: 10px;
            cursor: pointer;
            &:hover { color: $orange; }
        }

    }
    .members {
        height: 224px;
        overflow:scroll; 

        .spinnerRow {
            color:$grey-0;
            width: 254px;
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .newAccountButton {
            font-size: 14px;
            padding:0 10px 5px;
            margin: 0 10px;
            border-bottom:1px solid $grey-2;
            cursor: pointer;
            &:hover { color: $orange; }
        }

        .member {
            font-size: 14px;
            padding:5px 20px;
            margin: 0;
            cursor: pointer;
            &:hover { color: $orange; background:$poppy; }
        }
    }
}
.addPopoverOpen {
    color: $orange;
}
.popoverButton { cursor: pointer; }


.AddUserPopover.createNew {
    width: 254px;
    height: auto;
    header {
         position: relative;
         height: auto;
         font-size: 14px;
         font-weight: bold;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
         .backButton {
             position: absolute;
             top: 10px; 
             left:15px;
             width: 30px;
             height: 30px;
         }
        }
    form {
        padding: 0 20px 20px;
        .control{ margin-bottom: 10px; }
    }
    .buttons {
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        .Button { flex:1; font-weight: bold; }
    }
}