@import "../../../styles.scss";

.UploadDataPoints {
  .modalCardContent {
    text-align: center;
    padding: 80px;
  }

  
}
