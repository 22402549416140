.ExercisesView {
.exerciseAndInstructionBox{
    position: absolute;
    top:180px; 
    right: 20px;
    bottom:20px;
    left:20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.demoTools { 
    position: fixed;
    right: 30px;

}
}