@import "../../../styles.scss";

.SubTaskDetailsView{
  .detailsHeader {
    padding: 26px 20px 80px 20px;
    color:$text-light;
    h2 {
      font-size: 2em;
      font-family: $family-secondary;
      color: $text;
    }
    .actionButton {
      position: absolute;
      right: 20px;
      top:26px;
    }
  }
  .modelData,
  .examplePhrases {

    padding: 26px 20px 40px 20px;
    h3 {
      font-size: 1.2em;
      font-family: $family-secondary;
      color: $text;
      margin-bottom: 0.3em;

     }
     .Pill {
       background-color: #fff;
       margin: 0 10px 10px 0;
     }
  }
  .examplePhrase { margin-bottom: 15px;}

}
