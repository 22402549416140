@import "../../../styles.scss";

.JobReviewScreen {
  h1 {
    font-family: $family-secondary;
    color:$light-brown;
    font-size: 24px;
    margin: 20px 20px;
    font-weight: 550;

  }
    .loadingReview {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        span { 
            font-size: 42px;
            text-align: center;
            display:block ;
            width: 100px;
            height: 80px;
            border-radius: 50px;
            // overflow: hidden;
          animation: waver 0.65s alternate infinite;
          animation-timing-function: ease-in-out;
          margin-bottom: 10px;        
        }
    }

  @keyframes waver {
    0% {transform: rotate(-15deg);}
    100% {transform: rotate(20deg);}
  }
    .screen {
        position: absolute;
        top:70px;
        left:0;
        right: 0;
        bottom:0;
        padding: 20px  20px;
        overflow-y: scroll;
    }

  .pagination ul {
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
  }
  
  .pagination li a {
      min-width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      display: inline-block;
      padding: 0 10px;
   
      &:hover {
        background-color: #E9E3E0;
      }

  }
  .pagination li.active a{
    background-color: #fff;
  }

}