@import "../../../styles.scss"; // This must be loaded first to overwrite bulma.
.NavMenuWrapper {
  .Fade {
    position: fixed;
    top:0; right:0; bottom:0; left:0;
    z-index: 5000;
    background-color: rgba(65,59,56,0.1);

    div {
      position: fixed;
      top:0; right:0; bottom:0; left:0;
    }
  }
}
.NavMenu  {
  position: fixed;
  bottom: -300px;
  left: 30px;
  z-index: 10002;
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .15);
  padding: 20px 20px 0;
  height: 180px;
  // width: 1060px;
  // transition: all 0.3s;
  opacity: 0;
  transition-timing-function: ease-out;
  transition: 0.25s;

}
.NavMenu.isShowing {
  transform: translateY(-400px);
  opacity: 1;

  transition-timing-function: ease-in;
  transition: 0.2s;

}

// .NavMenu {
  // .mainNavButton {
  //   float: left;
  //   width: 184px;
  //   height: 140px;
  //   display: inline-flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   text-align: center;
  //   border-radius: 25px;
  //   transition: 0.25s background-color;
  //   position: relative;
  //   margin-left: 10px;
  //   .icon {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     width: 140px;
  //     height: 90px;
  //   }
  //   .title {
  //     font-family: 'Poppins', sans-serif;
  //     font-weight: 550;
  //     font-size: 24px;
  //     color: $dark-brown;
  //     padding-bottom: 10px;
  //   }
  // }
//   .mainNavButton:hover {
//         background-color: $grey-5;
//   }
//   .mainNavButton:first-child {
//     margin-left: 0;
//   }

// }





.navButton {
  position: absolute;
  bottom: 30px;
  left: 20px;
  z-index: 9995;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(65, 59, 56, 0.15);
}

.navButton:hover {
  cursor: pointer;
}

.navButton.closeNav {
  background-color: #fff;
}

.navButton.openNav {
  background-color: #E92200;
}

.redStick, .yellowStick {
  width: 26px;
  height: 3px;
  border-radius: 36px;
  z-index: 10003;
  left: 18px;
  position: absolute;
}

.navButton.openNav .redStick {
  bottom: 28px;
  transition: all .2s ease;
  -webkit-transform: rotate(45deg) translateZ(0);
  transform: rotate(45deg) translateZ(0);
  background-color: #fff;
}

.navButton.openNav .yellowStick {
  bottom: 28px;
  transition: all .2s ease;
  -webkit-transform: rotate(-45deg) translateZ(0);
  transform: rotate(-45deg) translateZ(0);
  background-color: #fff;
}

.buttonLoaded .navButton.closeNav .yellowStick {
  background-color: #FFA900;
  bottom: 22px;
  transition: all .2s ease;
  -webkit-transform: rotate(0deg) translateZ(0);
  transform: rotate(0deg) translateZ(0);
}

.noButtonLoaded .navButton.closeNav .yellowStick {
  background-color: #FFA900;
  bottom: 22px;
}

.buttonLoaded .navButton.closeNav .redStick {
  background-color: #E92200;
  bottom: 32px;
  transition: all .2s ease;
  -webkit-transform: rotate(0deg) translateZ(0);
  transform: rotate(0deg) translateZ(0);
}

.noButtonLoaded .navButton.closeNav .redStick {
  background-color: #E92200;
  bottom: 32px;
}

.navButton.closeNav:hover {
  background-color:#FFF5E2;
}

.navButton.openNav:active { //when x is clicked
  animation: .5s ease rotateXCounter;
}

.navButton.closeNav:active { //when burger is clicked
  animation: .2s ease rotateHooverClockwise;
}
