@import "../node_modules/@swivl/great-grey-components/build/Styles.scss"; // This must be loaded first to overwrite bulma.

label {
    font-size: 12px;
    color:$grey-0;
    font-weight: bold;
    text-transform: uppercase;
}
.react-tiny-popover-container { z-index: 9999; }

.Switch {
    z-index: 1;
}