.TextToken {
    display: inline-block;
    line-height: 30px;
    margin-bottom: 15px;
    &.isSPACE { display: inline; }
    position:relative;
    font-size: 20px;
    border-radius: 3px;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
    font-weight: 600;
    .entityLabel {
        position: absolute;
        font-size: 10px;
        font-weight: bold;

        position: absolute;
        left: 50%; /* position the left edge of the element at the middle of the parent */
    
        transform: translate(-50%, 0);
        text-align: center;

        bottom: -15px;
        line-height: 15px;
        white-space: nowrap;
    }

    &.isENTITY {
        background-color: #fff;
        cursor: pointer;
    }
    &.isWORD {
        cursor: pointer;
        &:hover {
            color: #fff;
            background-color: #5E5450;
        }

    }
}