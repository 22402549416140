@import "../../../styles.scss";

.UploadMLModel {
  .modalCardContent {
    text-align: center;
    padding: 80px;
  }

 
}
