@import "../../styles.scss"; // This must be loaded first to overwrite bulma.

.dataPointsCell {
    display: block;
    background-color: $poppy;    
    padding: 10px;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid $orange;
    cursor: pointer;
    color: $text;
    &:hover {
        color: $orange;
    }
}