.AudioPlayer {
    margin-top: 40px;
    width: 100vw;
    // background-color: #cccccc;
    height: 300px;
    clear: both;
    margin-bottom: 50px;

    .timelineControls {
        position: absolute;
        top: 194px; left: 0; right:0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}