.WaveformWrapper {
    position: absolute;
    left: 0; right:0;
    height: 164px;
    overflow-x: scroll;
    overflow-y: hidden;


    .playbackArea {
    
        position: absolute;
        left: 0; 
        height: 154px;

}
}