@import "../../../../styles.scss"; // This must be loaded first to overwrite bulma.

.IntentExerciseView {
    position: absolute;
    left: 0;
    right: 0;
    bottom:0;
    top:0;

    .utteranceRow {
        position: absolute;
        padding: 0 20% 0 20%;
        top:0;
        height: 35%;
        left: 0;
        right: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
    }
    .selectionRow {
        position: absolute;
        left: 0;
        right: 0;
        bottom:0;
        height: 65%;
        display: flex;
        .SelectionList {
            width: 600px;
            margin: 40px auto 0 auto;
        }
    }

    .utterance {
        text-align: center;
        color:$dark-brown;
        font-family: $family-secondary;
        font-weight: 550;
        font-size: 20px;
    }
}