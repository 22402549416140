.ImageCategorizeExerciseViewButton {
    cursor: pointer;
    display: inline-block;
    background: #fff;
    font-size: 14px;
    min-width: 136px;
    padding: 0 22px;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    text-align: center;
    line-height: 44px;
    margin: 10px;

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);

    }
    &:active {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);

    }
}