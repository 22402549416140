@import "../../../../../../styles.scss"; // This must be loaded first to overwrite bulma.

.ProjectMemberPermissionsPopover {
    padding: 1px 10px 0 0;
    float: right;
    .popoverButton {
        background-color: #fff;
        width: 93px;
        height: 28px;
        font-size: 13px;
        font-weight: 600;
        padding: 0 10px 0 10px;
        line-height: 28px;
        text-transform: capitalize;
        border-radius: 3px;
        svg {
            float: right;
            margin: 8px 0 0 0;
        }
    }
 
}
.popoverPanel {

    width:254px;
    padding: 10px 0;

    svg {
        position: absolute;
        top: 13px;
        left:10px;
    }

    h3 { 
        text-align: center;
        margin-bottom: 10px;
    }

    .optionRow {
        padding: 0 15px;

        font-size: 13px;        
        margin-bottom: 5px;
        width: 100%;
        height: 27px;
        .Switch {
            float: right;
        }
    }

    .permissionsButton {
        font-weight: bold;
        font-size: 13px;
        height: 32px;
        line-height: 32px;
        padding: 0 15px;
        cursor: pointer;
        color:$text;

        span { 
            float:right;
            font-weight: normal; 
            color:$text-light;
        }
        &:hover {
            background-color: $poppy;
            color:$orange;
        }
        &:active {
            background-color: $poppy;
            color:$yellow;
        }
    }
}