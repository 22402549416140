.PlayheadView {
    position: absolute;
    top:0; bottom:0; 
    width: 2px;
    z-index: 600;


    .mainPlayhead {
        width: 2px;
        background-color: #F67902;
        height: 154px;
        margin-bottom: 10px;
    }

    

    

}