
$color1:       #00CABE;
$color1-dark:  #027C74;
$color1-light: #C7DFDA;


$color2:       #FD583B;
$color2-dark:  #8F1500;
$color2-light: #EBCEC6;

$color3:       #BD10E0;
$color3-dark:  #69007F;
$color3-light: #E2C2DF;


$color4:       #5E5450;
$color4-dark:  #413B38;
$color4-light: #D4CEC9;

$color5:       #FFA900;
$color5-dark:  #F67802;
$color5-light: #EBDBBD;


$colors: $color1,  $color2,  $color3, $color4, $color5; 
$colors-dark: $color1-dark,  $color2-dark,  $color3-dark, $color4-dark, $color5-dark; 
$colors-light: $color1-light,  $color2-light,  $color3-light, $color4-light, $color5-light; 
// .CategoryTitles h1:nth-child(5n+1) {  background-color: $color1; }
// .CategoryTitles h1:nth-child(5n+2) {  background-color: $color2; }
// .CategoryTitles h1:nth-child(5n+3) {  background-color: $color3; }
// .CategoryTitles h1:nth-child(5n+4) {  background-color: $color4; }
// .CategoryTitles h1:nth-child(5n+5) {  background-color: $color5; }

@for $i from 1 through 5 {
    .CategoryTitles h1:nth-child(5n+#{$i}) {  background-color: nth($colors, $i); }

    .CursorView .trackCursors .rowCursor:nth-child(5n+#{$i}) {  background-color: nth($colors, $i); }

    

    .SelectionView .selectionRows .rowSelection:nth-child(5n+#{$i}) {
        background-color: nth($colors-light, $i); 
        &.doneSelecting {
            border-color:  nth($colors, $i);
            span { background-color: nth($colors, $i); }

            &:hover{ background-color: nth($colors, $i);
                border-color: nth($colors-dark, $i);
                 span {background-color: #fff;}
                 }
            &:active{ span {background-color:nth($colors-light, $i);} }

        }

    }

    


.timelineSegmentsRow:nth-child(5n+#{$i}) .TimelineSegment {
    background-color: nth($colors, $i);
    border-color: nth($colors-dark, $i);
    .marker { background-color: nth($colors, $i); }
    .segmentMenu .segmentMenuArrow, .segmentMenu { color: nth($colors, $i); background-color: nth($colors-dark, $i); }
    .segmentMenu a { color: nth($colors, $i); }
    .segmentMenu a:hover { color: #fff; }
    .segmentMenu a:active { color: rgba(255,255,255,0.7) }
 }

}
