@import "../../styles.scss"; // This must be loaded first to overwrite bulma.

.SettingsScreen {
    display: flex;
    flex-direction: column;
    .settingsHeader {
        h1 {
            font-size: 20px;
            font-family: $family-secondary;
            font-weight: 600;
        }
    }
    .orgWrapper {
        position: relative;
        width: 300px;
        height: 200px;
    }
}