@import "../../../../styles.scss";

.JobTask {
    background-color: #fff;
    border-radius: 8px;
    padding: 12px 25px;
    margin-bottom: 10px;
    .header {
        min-height: 40px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        h3 { 
            color:$light-brown;
            font-weight: 550;
            font-size: 18px;
            font-family: $family-secondary;
            margin-bottom: 0.1em;
        }
        .title {
            position: absolute;
            left: 0;
            top:0; 
            bottom:0;
            right: 100px;
        }
        .status,
        .Switch { float: right;}
    }
    h5 { 
        font-weight: 550;

        font-size: 14px;
        margin: 10px 0 20px;
        text-transform: uppercase;
        color:$grey-0;
        font-family: $family-secondary;

    }
}