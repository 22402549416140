@import "../../styles.scss"; // This must be loaded first to overwrite bulma.

.InviteScreen {
    position: absolute;
    left:0;
    right:0;
    top:70px;
    bottom:0; 
    .leftColumn {
        position: absolute;
        left:0;
        top:0;
        bottom:0; 
        width: 50%;
        background-color: #fff;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        .inputColumn {
            padding-top: 10px;
            max-width: 262px;
            flex-direction: column;
            display: flex;
            align-items: flex-start;
            justify-content: center;

            h1 { 
                color: $text;

                text-align: center; 
                margin-bottom: 20px;}

            h3 {
                width: 262px; 
                margin: 0 0 15px 0;
                border: 1px solid $orange;
                color:$text;
                border-radius: 4px;
                padding: 7px 11px;
                cursor:not-allowed;
            }

            .Button {width: 262px; margin: 15px 0;}

            .control {width: 262px; margin-bottom: 10px;}

            .legal {
                color: $text;

                font-size: 12px;
                text-align: center;
                a {
                    color: $text;
                    text-decoration: underline;
                }
            }
        }
    }
    .rightColumn {
        position: absolute;
        right:0;
        top:0;
        bottom:0; 
        width: 50%;

        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width:123px;
            height:263px; 
        }
    }

}