@import "../../../../../styles.scss";

.FilePicker {
  padding: 80px;
  .modalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dropArea {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      color:$grey-2;
    }
    h4 {
      font-size: 24px;
      color:$text;
      margin: 20px 0;
    }
    .fauxButton {
      padding: 5px 40px;
      border-radius: 3px;
      color:#fff;
      background-color: $text;
      cursor: pointer;
    }
    .fauxButton:hover {
      background-color: $orange;
    }
  }
}
