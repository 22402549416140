@import "../../../../styles.scss"; // This must be loaded first to overwrite bulma.

.OrgMemberView {
    background: $grey-4;
    height: 50px;
    border-radius: 8px;
    padding: 5px;
    line-height: 40px;
    margin-bottom: 10px;
    .squareAvatar{ float: left; margin:5px 10px 0 0; }

    .name {
        font-weight: bold;
        margin-right: 10px;

    }
    .remove {
        font-size: 13px;
        float: right;
        color:$text;
        cursor: pointer;
        display: none;
        margin-right: 10px;
        &:hover {
            color:$orange
        }
    }
    .role {
        line-height: 15px;

        float: right;
        width: 150px;
        font-size: 12px;
    }
    &:hover {
        .remove { display: inline-block; }
    }
}