@import "../../../../styles.scss"; // This must be loaded first to overwrite bulma.

.CreateDataSetFromDataPointsModal {
    h1 {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        font-family:$family-secondary;
        margin-bottom: 20px;
    }
    .buttons {
        height: 80px;

        display: flex;
        flex-direction: row;
        padding-top: 15px;
        .Button {
            flex:1
        }
    }
    .buttonOrSpinner {
     
        width: 100%;
        height: 60px;
        .loadingSpinner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 60px;
        }
    }
    .Button {
        
        width: 100%;
    }



     
      
}
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid $orange;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
@keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

.loaderWrapper {
    margin: 0 auto;
    position: relative;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}


